import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/landscape-equipment/3-point-mounted-sprayers/',
    imageId: 'threePointSprayersImage',
    name: '3-Point Mounted Sprayers',
    alt: '3-Point Mounted Sprayers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/box-blades/',
    imageId: 'boxBladesImage',
    name: 'Box Blades',
    alt: 'Box Blades',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/box-scrapers/',
    imageId: 'boxScrapersImage',
    name: 'Box Scrapers',
    alt: 'Box Scrapers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/core-aerators/',
    imageId: 'coreAeratorsImage',
    name: 'Core Aerators',
    alt: 'Core Aerators',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/debris-blowers/',
    imageId: 'debrisBlowersImage',
    name: 'Debris Blowers',
    alt: 'Debris Blowers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/land-planes/',
    imageId: 'landPlanesImage',
    name: 'Land Planes',
    alt: 'Land Planes',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/landscape-rakes/',
    imageId: 'landscapeRakesImage',
    name: 'Landscape Rakes',
    alt: 'Landscape Rakes',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/post-hole-diggers/',
    imageId: 'postHoleDiggersImage',
    name: 'Post Hole Diggers',
    alt: 'Post Hole Diggers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/power-rakes/',
    imageId: 'powerRakesImage',
    name: 'Power Rakes',
    alt: 'Power Rakes',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/rear-blades/',
    imageId: 'rearBladesImage',
    name: 'Rear Blades',
    alt: 'Rear Blades',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/soil-pulverizers/',
    imageId: 'soilPulverizersImage',
    name: 'Soil Pulverizers',
    alt: 'Soil Pulverizers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/trenchers/',
    imageId: 'trenchersImage',
    name: 'Trenchers',
    alt: 'Trenchers',
  },
  {
    link: '/implements/utility-tractors/landscape-equipment/wood-chippers/',
    imageId: 'woodChippersImage',
    name: 'Wood Chippers',
    alt: 'Wood Chippers',
  },
]

const LandscapeEquipmentPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Landscape Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Grade driveways, rake debris, move dirt, dig trenches, and more with Frontier landscape equipment for John Deere utility tractors at Hutson.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Landscape Equipment',
                'item':
                  'https://www.hutsoninc.com/implements/utility-tractors/landscape-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Landscape Equipment' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment-header.jpg" }
    ) {
      ...FullWidthImage
    }
    threePointSprayersImage: file(
      relativePath: {
        eq: "implements/utility-tractors/landscape-equipment/3-point-mounted-sprayers.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    boxBladesImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/box-blades.jpg" }
    ) {
      ...FloatingGridImage
    }
    boxScrapersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/box-scrapers.jpg" }
    ) {
      ...FloatingGridImage
    }
    coreAeratorsImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/core-aerators.jpg" }
    ) {
      ...FloatingGridImage
    }
    debrisBlowersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/debris-blowers.jpg" }
    ) {
      ...FloatingGridImage
    }
    landPlanesImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/land-planes.jpg" }
    ) {
      ...FloatingGridImage
    }
    landscapeRakesImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/landscape-rakes.jpg" }
    ) {
      ...FloatingGridImage
    }
    postHoleDiggersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/post-hole-diggers.jpg" }
    ) {
      ...FloatingGridImage
    }
    powerRakesImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/power-rakes.jpg" }
    ) {
      ...FloatingGridImage
    }
    rearBladesImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/rear-blades.jpg" }
    ) {
      ...FloatingGridImage
    }
    soilPulverizersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/soil-pulverizers.jpg" }
    ) {
      ...FloatingGridImage
    }
    trenchersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/trenchers.jpg" }
    ) {
      ...FloatingGridImage
    }
    woodChippersImage: file(
      relativePath: { eq: "implements/utility-tractors/landscape-equipment/wood-chippers.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default LandscapeEquipmentPage
